import cn from 'classnames';
import { Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import style from './style';
import { get } from 'lodash';

const Styled = styled(Layout.Container)`
  ${style}
`;

export const ListItem = ({
  item,
  idx,
  handleRowClick,
  handleDeleteClick,
  disabled,
  paths,
  paymentMethod,
  ...props
}) => {
  const isDisabled = React.useMemo(() => {
    return disabled?.(item);
  }, [disabled, item]);

  const handleClick = React.useCallback(
    e => {
      e.stopPropagation();

      if (isDisabled) return;
      handleDeleteClick(item);
    },
    [handleDeleteClick, isDisabled, item]
  );

  const handleItemClick = React.useCallback(() => {
    handleRowClick(item);
  }, [handleRowClick, item]);

  const flags = React.useMemo(() => {
    return {
      isPrimary: get(item, paths.isPrimary),
      isReasonFieldEnabled: get(item, paths.isReasonFieldEnabled) === true, // || isUndefined(get(item, paths.isReasonFieldEnabled)),
      isConcreteOnTruckFieldEnabled: get(item, paths.isConcreteOnTruckFieldEnabled) === true, //|| isUndefined(get(item, paths.isConcreteOnTruckFieldEnabled)),
      isTimeFieldEnabled: get(item, paths.isTimeFieldEnabled) === true, // || isUndefined(get(item, paths.isTimeFieldEnabled)),
      isSplitLoad: get(item, paths.isSplitLoad),
    };
  }, [
    item,
    paths.isConcreteOnTruckFieldEnabled,
    paths.isPrimary,
    paths.isReasonFieldEnabled,
    paths.isSplitLoad,
    paths.isTimeFieldEnabled,
  ]);

  if (!flags.isPrimary && !flags.isSplitLoad) {
    let description = '';

    if (Array.isArray(paths.description)) {
      description = paths.description
        ?.map(path => get(item, path))
        ?.filter(Boolean)
        ?.reduce((acc, curr) => {
          if (acc === '') {
            return `${acc}${curr}`;
          }

          return acc;
        }, '');
    }

    if (!Array.isArray(paths.description)) {
      description = get(item, paths.description);
    }

    return (
      <Styled
        className={cn('list-item', { hasError: !!props.errors?.[paths.errors]?.[idx] })}
        onClick={handleItemClick}
      >
        <div className="description-delete">
          <div className="description">
            <Typography.H3 data-testid="product-value">{description}</Typography.H3>
          </div>
          <div className="delete" onClick={handleClick}>
            <h3>
              <i className={cn('delete-icon fa-regular fa-trash', { disabled: isDisabled })} />
            </h3>
          </div>
        </div>
        {get(item, paths.driverDidNotAdd) !== true ? (
          <>
            <div className="label-value">
              <div className="label">
                <Localization.Translate stringId="quantity" data-testid="quantity-header" />
              </div>
              {get(item, paths.driverDidNotAdd) !== true && <Localization.Uom uom={get(item, paths.quantity)} />}
            </div>
            {flags.isReasonFieldEnabled === true && (
              <div className="label-value">
                <div className="label">
                  <Localization.Translate stringId="reason" data-testid="reason-header" />
                </div>

                {get(item, paths.reason)}
              </div>
            )}

            {flags.isConcreteOnTruckFieldEnabled === true && (
              <div className="label-value">
                <div className="label">
                  <Localization.Translate stringId="materialOnTruck" data-testid="concreteOnTruck-header" />
                </div>
                <Localization.Uom uom={get(item, paths.concreteOnTruck)} />
              </div>
            )}
            {flags.isTimeFieldEnabled === true && (
              <div className="label-value">
                <div className="label">
                  <Localization.Translate stringId="time" data-testid="time-header" />
                </div>

                <Localization.Time date={get(item, paths.time)} />
              </div>
            )}
            {Boolean(item?.priceSummary?.unitPrice) && paymentMethod?.isCOD && (
              <div className="label-value">
                <div className="label">
                  <Localization.Translate stringId="unitPrice" data-testid="unit-price-header" />
                </div>

                {get(item, paths.unitPrice)}
              </div>
            )}
          </>
        ) : (
          <div className={'didNotAdd'}>
            <Localization.Translate stringId="driverDidNotAdd" data-testid="driverDidNotAddText" />
          </div>
        )}
      </Styled>
    );
  }
};
