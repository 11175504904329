import { css } from 'styled-components';

export default css`
  &.form-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 20px;

    @media (max-width: 950px) {
      .container {
        padding: 10px;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      flex: 1;

      .row-1 {
        @media (min-width: 951px) {
          display: flex;
          width: 100%;
          & > * {
            &:not(:first-child) {
              margin-left: 10px;
            }
            flex: 1;
          }
        }
      }
      .row-2 {
        @media (min-width: 951px) {
          display: flex;
          .left {
            display: flex;
            flex: 1;
            & > * {
              flex: 1;
              margin-right: 10px;
            }
          }
        }

        &.disabled * div.time-drawer {
          color: var(--color-text-select-disabled) !important;
          border-color: var(--color-border-select-disabled);
          background-color: var(--color-background-select-disabled);
          cursor: not-allowed;
        }
      }

      .row-4 {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  .actions {
    margin-top: 20px;

    .left {
      margin-right: 10px;
      flex: 1;
    }
  }
`;
